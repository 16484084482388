import { Icon } from '@mui/material';
import React from 'react';
import { MATERIAL_UI_ICONS } from '../../constants/types';

const VIcon = ({ name, color, size, baseClassName = MATERIAL_UI_ICONS.OUTLINED, ...props }) => {
  return (
    <Icon baseClassName={baseClassName} sx={{ color, fontSize: size }} {...props}>
      {name}
    </Icon>
  );
};

export default VIcon;
