import React from 'react';
import { withStyles } from '@material-ui/core';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import { Button, Stack } from '@mui/material';
import { useSidebar } from './Sidebar.hook';
import styles from './Sidebar.styles';
import SidebarItemButton from './SidebarItemButton/SidebarItemButton';
import VTypography from '../../Venti-UI-Kit/VTypography/VTypography';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';
import { Drawer } from './Sidebar.styled';

const Sidebar = ({ classes, sections, footerUrl, footerUrlLabel, footerLabel }) => {
  const {
    open,
    sectionSelected,
    selectedSectionComponent,
    handleToggleSidebarOpen,
    openNewURL,
    handleClickSection,
  } = useSidebar(sections);

  return (
    <Stack direction="row" className={classes.sidebarContainer}>
      <Drawer variant="permanent" open={open}>
        <Toolbar />
        <Button
          disableRipple
          sx={{ alignSelf: open ? 'end' : 'center' }}
          className={classes.toggleSidebarButton}
          onClick={handleToggleSidebarOpen}
        >
          <VIcon name={open ? 'chevron_left' : 'menu'} />
        </Button>
        <Stack flex={1}>
          {sections.map(({ title, key, icon, show, url }) => (
            <ListItem key={title} disablePadding>
              {show && (
                <SidebarItemButton
                  active={sectionSelected === key}
                  onClick={() => (url ? openNewURL(url) : handleClickSection(key))}
                  href={url}
                  open={open}
                  title={title}
                  icon={icon}
                />
              )}
            </ListItem>
          ))}
        </Stack>
        <Stack padding={2}>
          {open && footerUrlLabel && (
            <a className={classes.footerUrl} href={footerUrl}>
              <VIcon name="arrow_back" size={16} />
              <VTypography variant="subtitle6">{footerUrlLabel}</VTypography>
            </a>
          )}
          {open && footerLabel && <VTypography variant="subtitle3">{footerLabel}</VTypography>}
        </Stack>
      </Drawer>
      <Stack flex={1} alignItems="center">
        {selectedSectionComponent}
      </Stack>
    </Stack>
  );
};

export default withStyles(styles)(Sidebar);
