import React from 'react';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import { useProducerPage } from './ProducerPage.hook';

const ProducerPage = ({ urlParams }) => {
  const { producerId } = urlParams;
  const { sections, producer } = useProducerPage(producerId);

  return (
    <PageContainer title={`Backoffice - ${producer?.name}`}>
      {producer && (
        <Sidebar
          sections={sections}
          producerId={producerId}
          footerLabel={producer.name}
          footerUrlLabel="Ver productoras"
          footerUrl={'/backoffice/productoras'}
        />
      )}
    </PageContainer>
  );
};

export default ProducerPage;
