import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Person, Mail, Phone, FeaturedPlayList, Room, Public } from '@material-ui/icons';
import styles from './DataFormStep.styles';
import { INPUT_TYPE, NOTIFICATION_VARIANT } from '../../../constants/types';
import { ARGENTINA_PROVINCES } from '../../../constants/constants';
import { useForm } from '../../../hooks/useForm';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import Button from '../../Buttons/Button/Button';
import { ID_TYPE_OPTIONS } from '../../../constants/identificationTypes';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';

const DataFormStep = ({ classes, handleBack, setOrder, handleNext }) => {
  const [isComplete, setIsComplete] = useState(false);
  const { showNotification } = useNotifications();
  const { user } = useAuth();

  const { formState, handleInputChange, isFormComplete, validateFormInputs } = useForm({
    firstName: {
      title: 'Nombre',
      type: INPUT_TYPE.ALPHA,
      value: user?.firstName,
    },
    lastName: {
      title: 'Apellido',
      type: INPUT_TYPE.ALPHA,
      value: user?.lastName,
    },
    idType: {
      title: 'Tipo de identificación',
      type: INPUT_TYPE.ID_TYPE,
      value: user?.identificationType || 'DNI',
    },
    idNumber: {
      title: 'Número de identificación',
      type: INPUT_TYPE.ID_NUMBER,
      value: user?.identificationValue,
    },
    mail: {
      title: 'Mail',
      type: INPUT_TYPE.MAIL,
      value: user?.mail,
    },
    confirmMail: {
      title: 'Confirmar mail',
      type: INPUT_TYPE.CONFIRM_MAIL,
      value: user?.mail,
    },
    phoneNumber: {
      title: 'Número de teléfono',
      type: INPUT_TYPE.NUMERIC,
      value: user?.phone,
    },
    province: {
      title: 'Lugar de residencia',
      type: INPUT_TYPE.TEXT,
      value: user?.residenceLocation || ARGENTINA_PROVINCES[1].label,
    },
  });

  const onSubmit = () => {
    const isFormValid = validateFormInputs();

    if (!isFormValid) {
      showNotification('Complete correctamente los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    setOrder((prevState) => ({
      ...prevState,
      user: {
        firstName: formState.firstName.value.trim(),
        lastName: formState.lastName.value.trim(),
        idType: formState.idType.value,
        idNumber: formState.idNumber.value,
        mail: formState.mail.value.trim().toLowerCase(),
        phoneNumber: formState.phoneNumber.value,
        province: formState.province.value,
      },
    }));

    handleNext();
  };

  useEffect(() => {
    setIsComplete(() => isFormComplete(formState));
  }, [setIsComplete, isFormComplete, formState]);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <TextInput
          title={formState.firstName.title}
          leftIcon={<Person />}
          onChange={handleInputChange}
          name="firstName"
          errorMessage={formState.firstName.error}
          defaultValue={formState.firstName.value}
        />
        <TextInput
          title={formState.lastName.title}
          leftIcon={<Person />}
          onChange={handleInputChange}
          name="lastName"
          errorMessage={formState.lastName.error}
          defaultValue={formState.lastName.value}
        />
        <SelectInput
          title={formState.idType.title}
          leftIcon={<FeaturedPlayList />}
          onChange={handleInputChange}
          name="idType"
          options={ID_TYPE_OPTIONS}
          value={formState.idType.value}
          errorMessage={formState.idType.error}
        />
        <TextInput
          title={formState.idNumber.title}
          leftIcon={<FeaturedPlayList />}
          onChange={handleInputChange}
          name="idNumber"
          errorMessage={formState.idNumber.error}
          defaultValue={formState.idNumber.value}
        />
        <TextInput
          title={formState.mail.title}
          leftIcon={<Mail />}
          onChange={handleInputChange}
          name="mail"
          errorMessage={formState.mail.error}
          defaultValue={formState.mail.value}
        />
        <TextInput
          title={formState.confirmMail.title}
          leftIcon={<Mail />}
          onChange={handleInputChange}
          name="confirmMail"
          errorMessage={formState.confirmMail.error}
          defaultValue={formState.confirmMail.value}
        />
        <TextInput
          title={formState.phoneNumber.title}
          leftIcon={<Phone />}
          onChange={handleInputChange}
          name="phoneNumber"
          errorMessage={formState.phoneNumber.error}
          defaultValue={formState.phoneNumber.value}
        />
        <SelectInput
          title={formState.province.title}
          leftIcon={<Room />}
          hint="Este dato se utilizará para facturarte"
          onChange={handleInputChange}
          name="province"
          value={formState.province.value}
          options={ARGENTINA_PROVINCES.map((province) => ({
            label: province.label,
            value: province.label,
            isCategory: province.isCategory,
          }))}
          errorMessage={formState.province.error}
          defaultValue={formState.province.value}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <Button onClick={handleBack} variant="outlined" title="Anterior" />
        <Button disabled={!isComplete} onClick={onSubmit} title="Siguiente" />
      </div>
    </div>
  );
};

export default withStyles(styles)(DataFormStep);
