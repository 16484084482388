import { useState } from 'react';
import { getUrlSearchParams } from '../../utils/utils';

export const useSidebar = (sections) => {
  const [open, setOpen] = useState(false);
  const { tab } = getUrlSearchParams();
  const [sectionSelected, setSectionSelected] = useState(tab);
  const [selectedSectionComponent, setSelectedSectionComponent] = useState(sections[0].component);

  const handleClickSection = (section) => {
    setSectionSelected(section);
    const newComponent = sections.find(({ key }) => key === section).component;
    setSelectedSectionComponent(newComponent);
    window.history.replaceState(null, null, `?tab=${section}`);
  };

  const openNewURL = (url) => {
    window.open(url, '_blank');
  };

  const handleToggleSidebarOpen = () => {
    setOpen(!open);
  };

  return {
    open,
    sectionSelected,
    selectedSectionComponent,
    openNewURL,
    handleClickSection,
    handleToggleSidebarOpen,
  };
};
